







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main_youtube_html_render_for_tasks {
  .row {
    flex-direction: row-reverse;
    ul {
      text-align: right;
      list-style: none;
      margin-left: 0 !important;
      padding-left: 0 !important;
      li {
        text-align: right;
        position: relative;
        padding-right: 17px;
        text-align: right;
        &::before {
          position: absolute;
          content: "";
          background-color: #706f6f;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          right: 0;
          top: 7px;
        }
      }
    }
  }
}

.new_wrapper_of_use_patterns {
  .want_break_word {
    p {
      span {
        word-wrap: break-word;
        white-space: pre-wrap !important;
      }
    }
  }
}
