











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.for_user_edit_scores_dialog {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.for_user_edit_details_dialog {
  .el-dialog__body {
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 30px 0 !important;
    }
  }
}

.just_for_showing_one {
  .el-collapse-item__header {
    padding: 2.5rem 0;
  }
  // .card-user .card-body {
  //   overflow: hidden;
  // }
}
